import { PUBLIC_OPENPANEL_CLIENT_ID as OPENPANEL_CLIENT_ID } from '$env/static/public';
import { OpenPanel } from '@openpanel/web';

export default function setupOpenPanel(filterPath?: string) {
	return new OpenPanel({
		clientId: OPENPANEL_CLIENT_ID,
		trackScreenViews: true,
		trackOutgoingLinks: true,
		trackAttributes: true,
		filter: () => {
			let enabled = localStorage?.getItem('dmarcwise/op/disabled') !== 'true';

			if (filterPath) {
				enabled &&= window.location.pathname.startsWith(filterPath);
			}

			return enabled;
		}
	});
}
