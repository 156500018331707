import { PUBLIC_PADDLE_TOKEN as PADDLE_TOKEN } from '$env/static/public';
import { dev } from '$app/environment';
import { initializePaddle } from '@paddle/paddle-js';

export default function setupPaddle() {
	return initializePaddle({
		environment: dev ? 'sandbox' : 'production',
		token: PADDLE_TOKEN
	});
}
